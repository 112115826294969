// import { createApp, h } from 'vue'
// import App from './App.vue'
// const app = createApp({
//   render: () => h(App)
// })

import { createI18n } from 'vue-i18n'
import store from './store/store'

// app.use(store)

// import App from "./App";
// Vue.use(VueI18n)

function InitBrowserLanguage() {
  let lang = 'undefined'

  if (store.state.lang !== undefined) {
    lang = store.state.lang
    // console.log('Get Local Data : ' + lang)

    if (lang !== undefined) return lang
  }

  if (navigator.language !== null) lang = navigator.language
  if (lang === 'undefined') return process.env.VUE_APP_I18N_LOCALE

  lang = lang
    .toLowerCase()
    .substring(lang.toLowerCase().indexOf('-') + 1, lang.length)

  // console.log('Get Browser Language : ' + lang)

  return lang
}

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}

  console.log(locales)
  
  locales.keys().forEach((key) => {
    console.log(key)
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)

    if (matched && matched.length > 1) {
      // eslint-disable-next-line prefer-destructuring
      const locale = matched[1]

      messages[locale] = locales(key)
    }
  })

  return messages
}

export default createI18n({
  locale: InitBrowserLanguage() || 'en',
  // locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true
})
//
// export default new VueI18n({
//   locale:InitBrowserLanguage() || 'en',
//   // locale: process.env.VUE_APP_I18N_LOCALE || 'en',
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
//   messages: loadLocaleMessages(),
//   silentTranslationWarn:true
// })
