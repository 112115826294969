// import Vue from 'vue'
// import Router from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

// Vue.use(Router)

export const routes = [
  {
    path: '',
    component: () => import(/* webpackChunkName: "layout-simple" */ '@/layouts/SimpleLayout.vue'),
    children: [{
      path: '', //name: 'starter-pages-modules',
      component: () => import( '@/pages/IntroPage.vue')
    }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        reject
        setTimeout(() => {
          resolve(savedPosition)
        }, 10)
      })
    } else {
      return { top: 0 }
    }
  }
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  to
  from

  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
  to
  from
})

export default router
