import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import * as mdijs from 'mdi-vue/v3'
import mdiVue from 'mdi-vue/v3'
import i18n from './i18n'
import store from './store/store'
import Vue3TouchEvents from 'vue3-touch-events'

const app = createApp(App)
  .use(router)
  .use(vuetify)
  .use(mdiVue, {
    icons: mdijs
  })
  .use(i18n)
  .use(store)
  .use(Vue3TouchEvents)

app.mount('#app')

global.isLoadingCompleted = false
