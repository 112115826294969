// import { createApp, h } from 'vue'
// import App from '../App.vue'
// const app = createApp({
//   render: () => h(App)
// })

import { createStore } from 'vuex'

// app.use(Vuex)

const LANG_KEY = 'language'

const initLang = (() => {
  const lang = window.localStorage.getItem(LANG_KEY) || window.navigator.language

  return lang || 'en'
})()

const state = {
  lang: initLang
}

const actions = {
  changeLanguage ({ commit }, payload) {
    commit('onLangChanged', payload)
  }
}

const mutations = {
  onLangChanged (state, payload) {
    window.localStorage.setItem(LANG_KEY, payload.lang)

    payload.i18n.locale = payload.lang

    state.lang = payload.lang
  }
}

export default createStore({
  state,
  mutations,
  actions,
  getters:{}
})
//
// export default new Vuex.Store({
//   state,
//   mutations,
//   actions,
//   getters:{}
// })
