<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import config from './configs'

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
*/
export default {
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  },
  watch: {
    $route: {
      handler: function (val) {
        this.$vuetify.theme.dark = val.query.theme === 'dark'
      },
      deep: true
    }
  },
  created() {
    // 환경 변수 출력
    // console.log(process.env)
  }
}
</script>
